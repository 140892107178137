import React from "react";
import { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./App.css";
import Subscribe from "./Components/SubscribeComponent";

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

export default class Demo extends Component {

  render() {
    return (
      <TableContainer component={Paper} class="tablebg">
        <Table class="table" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell class="top" valign="baseline">
                <a href="https://recruitingmonk.com/">
                  <img
                    class="top"
                    alt="Recruiting Monk"
                    src="https://recruitingmonk.com/wp-content/themes/discy-child-theme/assets/logo-recruitingmonk.png"
                  />
                </a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">
                <Subscribe />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableContainer className="second">
                <Table>
                  <TableBody>
                    <TableRow align="center">
                      <TableCell colSpan={2}>
                        <div class="head">Monk's Weekly</div>
                        <div class="release">(4 September, 2020, Release #12)</div>
                      </TableCell>
                    </TableRow>
                    <TableRow />
                        <TableRow>
                          <TableCell align="center">
                            <img
                              class="creator"
                              alt="Ashfaq Ahmed"
                              src="https://recruitingmonk.com/wp-content/uploads/2019/10/0.jpeg"
                            />
                          </TableCell>
                          <TableCell align="left">
                            <div class="head1">
                            Ashfaq Ahmed
                              <div align="left" class="head2">
                                Contributing Monk
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <p class="contributors">
                          <strong>Other contributors for the week :</strong>{" "}
                          Achyut Menon, Raghunandan Patre, Mitasha Singh, Pooja
                          Kadam & Hina Naaz.
                        </p>
                      </TableCell>
                    </TableRow>
                    <TableRow />
                    <TableRow>
                      <TableCell colSpan={2}>
                        <a
                          class="contentheading"
                          href="https://joshbersin.com/2020/09/introducing-resilient-hr-a-new-way-to-run-your-business/"
                          target="_blank"
                        >
                          Introducing Resilient HR: A New Way To Run Your Business
                        </a>
                      </TableCell>
                    </TableRow>
                    <TableRow className="toppp">
                      <TableCell>
                        <div class="contentinner">
                        From the very start of the pandemic, business leaders and HR teams have been in shock. Who is impacted? When do we bring people back to the workplace? What are our new work policies?
                        </div>
                      </TableCell>
                      <TableCell>
                        <div align="right">
                          <img
                            class="contentimages"
                            width="125"
                            height="120"
                            alt=""
                            align="right"
                            src="https://joshbersin.com/wp-content/uploads/2020/08/8-24-2020-5-09-48-PM.png"
                          />
                          <div class="contentcategory">Future of Work</div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <a
                          class="contentheading"
                          href="http://hrnews.co.uk/hr-teams-spending-thousands-on-benefits-but-have-no-idea-if-theyre-being-used/" 
                          target="_blank"
                        >
                          HR teams spending thousands on benefits but have no idea if they’re being used
                        </a>
                        </TableCell>
                        </TableRow>
                      <TableRow className="toppp">
                      <TableCell>
                        <div class="contentinner">
                        The pandemic has brought HRs and the HR tech market together. The next year is an opportunity for HR leaders to accelerate their work to create an integrated, healthy, and productive employee experience.
                        </div>
                      </TableCell>
                      <TableCell>
                        <div align="right">
                          <img
                            class="contentimages"
                            width="125"
                            height="120"
                            alt=""
                            align="right"
                            src="http://hrnews.co.uk/wp-content/uploads/2019/06/hr-news.jpg"
                          />
                          <div class="contentcategory">HRTech</div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <a
                          class="contentheading"
                          href="https://www.linkedin.com/posts/ashfaqahmedhr_recruitingmonk-activity-6705006548617457664-pfEq/"
                          target="_blank"
                        >
                          Do you know the purpose of Synonyms in your search?
                        </a>
                        </TableCell>
                      </TableRow>
                      <TableRow className="toppp">
                      <TableCell>
                        <div class="contentinner">
                        Sourcers use synonyms in their searches as a best practice without knowing the impact. This linkedin post speaks about why you need to know the purpose of your synonyms in the search.
                        </div>
                        </TableCell>
                        <TableCell>
                        <div align="right">
                          <img
                            class="contentimages"
                            width="125"
                            height="120"
                            alt=""
                            align="right"
                            src="https://lh3.googleusercontent.com/fqYJHtyzZzA4vacRzeJoB93QNvA5-mvR-8UB5oVLxdYDSTpfLp_KgYD4IqVGJUgFEJo"
                          />
                          <div class="contentcategory">Sourcing</div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <a
                          class="contentheading"
                          href="https://www.siliconrepublic.com/advice/employer-branding-remotely-how-to"
                          target="_blank"
                        >
                          8 ways to bring your brand to life when recruiting remotely
                        </a>
                        </TableCell>
                        </TableRow>
                        <TableRow className="toppp">
                        <TableCell>
                        <div class="contentinner">
                        What steps can you take to help bring your brand to life during the remote recruitment process?
                        </div>
                      </TableCell>
                      <TableCell>
                        <div align="right">
                          <img
                            class="contentimages"
                            width="125"
                            height="120"
                            alt=""
                            align="right"
                            src="https://img.resized.co/siliconrepublic/eyJkYXRhIjoie1widXJsXCI6XCJodHRwczpcXFwvXFxcL3d3dy5zaWxpY29ucmVwdWJsaWMuY29tXFxcL3dwLWNvbnRlbnRcXFwvdXBsb2Fkc1xcXC8yMDIwXFxcLzA5XFxcL0Fkb2JlU3RvY2tfMzIyNTU2MzUxLmpwZWdcIixcIndpZHRoXCI6MTEwMCxcImhlaWdodFwiOjYwMCxcImRlZmF1bHRcIjpcImh0dHBzOlxcXC9cXFwvd3d3LnNpbGljb25yZXB1YmxpYy5jb21cXFwvd3AtY29udGVudFxcXC91cGxvYWRzXFxcLzIwMTRcXFwvMTJcXFwvMjAxMzAyXFxcL3B1enpsZS5qcGdcIixcIm9wdGlvbnNcIjpbXX0iLCJoYXNoIjoiZDg2NzdhYWVhOWNiOTkzNWU2OWRhM2MwMDNiMzRiNjRkZTIwYWNiZCJ9/adobestock-322556351.jpeg"
                          />
                          <div class="contentcategory">Branding</div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow />
                    <TableRow>
                      <TableCell colSpan={2}>
                        <a
                          class="contentheading"
                          href="https://www.cnbc.com/2020/09/01/leaked-letter-google-recruiting-group-wants-clearer-diversity-goals.html" 
                          target="_blank"
                        >
                          Google recruiting group warns minority turnover will continue without clearer diversity goals
                        </a>
                        </TableCell>
                        </TableRow>
                        <TableRow className="toppp">
                      <TableCell>
                        <div class="contentinner">
                        Attrition rates for Google’s Black U.S. employees remain stagnant from a year ago at 12% — higher than the average Google employee — with Black females seeing a particular spike in attrition from last year, up 18% .
                        </div>
                      </TableCell>
                      <TableCell>
                        <div align="right">
                          <img
                            class="contentimages"
                            width="125"
                            height="120"
                            alt=""
                            align="right"
                            src="https://image.cnbcfm.com/api/v1/image/104640319-1592915390095preview.jpg?v=1596214764&w=740&h=416"
                          />
                          <div class="contentcategory">Diversity</div>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow />
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div class="contentinner">
                          Do you've a recruiting hiccup? Our Monks can help you
                          solve, shoot out your question{" "}
                          <Link class="links" href="https://recruitingmonk.com">
                            here
                          </Link>
                          .
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow />
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div class="head3">End Notes</div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div class="contributors">
                          <strong>Sharing Is Caring </strong>Let the Recruiting
                          World know about the top Monks awaiting to solve
                          recruiters' challenges at{" "}
                          <Link class="links" href="https://recruitingmonk.com">
                            recruitingmonk.com
                          </Link>
                          . Share it on your LinkedIn status update and
                          recommend it to your network - it’s time to help our
                          recruiting community.
                          <br />
                          Cheers!
                          <br />
                          Monk
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </TableRow>
            <TableRow>
              <TableCell>
                <div class="copyright">
                  © 2019 Recruiting Monk. All Rights Reserved.
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    );
  }
}