import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyAfLtMFDKGUh5Zs-dw6Z8I0apXRXZptflw",
  authDomain: "monknewsletter.firebaseapp.com",
  databaseURL: "https://monknewsletter.firebaseio.com",
  projectId: "monknewsletter",
  storageBucket: "monknewsletter.appspot.com",
  messagingSenderId: "432982396282",
  appId: "1:432982396282:web:35b5623af1d813af585ac3",
  measurementId: "G-GFSXPPMC0K"
};

var fire = firebase.initializeApp(firebaseConfig);
export default fire;