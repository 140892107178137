import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import fire from "../fire";

class Subscribe extends Component {
    state = {
        text: "",
        dis: true
      };
    
      handleText = e => {
        this.setState({
          text: e.target.value
        });
        if (
          e.target.value &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
        ) {
          return;
        }
        this.setState({
          text: e.target.value,
          dis: false
        });
      };
    
      handleSubmit = e => {
        let messageRef = fire
          .database()
          .ref("messages")
          .orderByKey()
          .limitToLast(100);
        fire
          .database()
          .ref("messages")
          .push(this.state.text);
    
        Swal.fire(
          "Thanks For Subscribing!",
          "You will now recieve our weekly newsletter!",
          "success"
        );
    
        this.setState({
          text: "",
          dis: true
        });
      };

      render() {

        return(
            <div>
                <form class="form" noValidate autoComplete="off">
                  <TextField
                    className="field"
                    onChange={this.handleText}
                    id="outlined-size-small"
                    label="Email"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={this.state.text}
                  />
                  <Button
                    className="submit"
                    variant="contained"
                    color="secondary"
                    disabled={this.state.dis}
                    onClick={this.handleSubmit}
                  >
                    Subscribe
                  </Button>
                </form>
            </div>
        );
      }
}

export default Subscribe;